import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
// import data from './data';

const Form = ({ doSubmit }) => {
  const [textCategory, setTextCategory] = useState('');
  const [fileImg, setFileImg] = useState('');
  const [selectCategory, setSelectCategory] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    console.log(textCategory);
    doSubmit(selectCategory, textCategory, fileImg);
    setTextCategory('');
    setFileImg(null);
  };

  // const [categories] = useState(data);

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <h2>Add Sub Category</h2>

      <div style={{ marginTop: '1rem' }}>
        <select
          name="category_id"
          onChange={e => {
            setSelectCategory(e.target.value);
          }}
        >
          <option>Select Category</option>
          <option value="1">Spaces</option>
          <option value="2">Used Equipments</option>
          <option value="3">Jobs</option>
          <option value="4">Market Deals in Your City</option>
        </select>
      </div>
      <div>
        <TextField
          id="standard-basic"
          label="Sub Category"
          name="name"
          value={textCategory}
          autoComplete="off"
          onChange={e => setTextCategory(e.target.value)}
        />
      </div>
      <br />
      <div>
        <input
          type="file"
          name="image"
          onChange={e => setFileImg(e.target.files[0])}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '1rem' }}
        onClick={handleSubmit}
      >
        Add
      </Button>
    </form>
  );
};

export default Form;

import React, { useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
// import data from './data';
// import axios from 'axios';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CategoryListView = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);

  // useEffect(() => {
  //   async function  fetch () {
  //     const response = await axios.get(
  //       `https://ohtelrest.hermitz.com/public/api/categories`
  //     );

  //     setCategories(response.data);

  //     // console.log(response.data);
  //   }
  //   fetch();
  // }, []);
  console.log(categories)

  return (
    <Page className={classes.root} title="Category">
      <Container maxWidth={false}>
        <Box mt={3}>
          <Results categories={categories} />
        </Box>
      </Container>
    </Page>
  );
};

export default CategoryListView;

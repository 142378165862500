import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'axios';

import { v4 as uuid } from 'uuid';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const Results = ({ className, categories, ...rest }) => {
  const classes = useStyles();
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([]);

  const [categories1, setcategories1] = useState(categories);
  console.log(categories1);

  useEffect(() => {
    async function fetch() {
      const response = await axios.get(
        `https://ohtelrest.hermitz.com/public/api/sub-categories`
      );

      setcategories1(response.data);

      // console.log(response.data);
    }
    fetch();
  }, []);

  const [limit, setLimit] = useState(150);
  const [page, setPage] = useState(0);

  const handleLimitChange = event => {
    setLimit(+event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // const handleDelete = e => {
  //   // Filter all todos except the one to be removed
  //   const remainder = categories1.filter(category => {
  //     if (category.id !== e.target.parentElement.id) return category;
  //   });
  //   console.log(e.target.parentElement.id);
  //   setcategories1(remainder);
  // };

  //  axios delete method hooks

  const handleDelete = e => {
    e.persist();
    axios
      .delete(
        `https://ohtelrest.hermitz.com/public/api/sub-categories/${e.target.parentElement.id}`
      )
      .then(res => {
        const remainder = categories1.filter(
          category => category.id !== e.target.parentElement.id
        );
        setcategories1(remainder);
      });
    console.log(e.target.parentElement.id);
  };

  // 2nd method axios delete

  //  handleRemove = movie => {
  //     const url = `http://localhost:3000/movies/${movie.id}`;

  //     axios
  //       .delete(url)
  //       .then(res => {
  //         this.setState(previousState => {
  //           return {
  //             movies: previousState.movies.filter(m => m.id !== movie.id)
  //           };
  //         });
  //       })
  //       .catch(err => {
  //         console.log(err);
  //       });
  //   };

  //   // ...
  // }

  console.log(categories1);

  const doSubmit = (category_id, name, fileImg) => {
    // const id = uuid();
    // console.log(name);
    // console.log(fileImg);

    // const newCategories = [...categories1, { name }];
    // let data = JSON.stringify({
    //   name: name
    // });

    let formData = new FormData();
    formData.append('category_id', category_id);
    formData.append('name', name);
    formData.append('image', fileImg);

    console.log(FormData);

    axios
      .post(
        `https://ohtelrest.hermitz.com/public/api/sub-categories`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )

      .then(function(response) {
        console.log(response);
      });

    // setcategories1(formData);
  };

  return (
    <>
      <Toolbar doSubmit={doSubmit} length={categories1.length} />
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Category ID</TableCell>
                  <TableCell>Category Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories1.slice(0, limit).map(category => (
                  <TableRow
                    hover
                    key={category.id}
                    selected={selectedCategoriesIds.indexOf(category.id) !== -1}
                  >
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {category.id}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell>{category.name}</TableCell>

                    <TableCell>
                      <Button
                        color="primary"
                        variant="contained"
                        // onClick={e => {
                        //   handleEdit();
                        // }}
                      >
                        Edit
                      </Button>
                      <Button
                        color="primary"
                        id={category.id}
                        variant="contained"
                        style={{ backgroundColor: 'red', marginLeft: '8px' }}
                        onClick={e => {
                          window.confirm('Are You Sure Delete This Category?');
                          handleDelete(e);
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          rowsPerPageOptions={[150, 10, 25]}
          count={categories1.length}
          rowsPerPage={limit}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
        />
      </Card>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array.isRequired
};

export default Results;
